<template>
  <div class="configs-module">
    <b-row>
      <b-col cols="12"><h3>Global referral rules for all clients</h3></b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-alert show variant="danger">Changing a states referral rules will also impact the identity check flow</b-alert>
      </b-col>
    </b-row>

    <div class="row mt-6" v-for="(other, key) in filterGroups" :key="key">
      <div class="col-sm groups-content">
        <div :id="'accordion-' + key">
          <div class="card">
            <div class="card-header" :id="'heading' + key">
              <h5 class="mb-0" data-toggle="collapse" :data-target="'#collapse' + key" aria-expanded="true"
                  :aria-controls="'collapse' + key">
                <feather type="arrow-down-circle" class="align-middle"></feather>
                <span class="align-middle"> {{other | sentencePascalCase }}</span>
              </h5>
            </div>

            <div :id="'collapse' + key" class="mb-0" :aria-labelledby="'heading' + key"
                 :data-parent="'#accordion-' + key">
              <div class="card-body">
                <div v-for="(config, index) in configs" :key="index">
                  <span v-if="config.type === 'json'">
                    <ReferralRulesInput :type="config.type"
                                  v-model="config.value"
                                  :inputKey="config.key"
                                  :label="config.label"
                                  @change="onChange">
                    </ReferralRulesInput>
                  </span>
                  <span v-else>
                    <!-- DynamicInput is here, but it's not configured -->
                    <DynamicInput :type="config.type"
                                  v-model="config.value"
                                  :inputKey="config.key"
                                  :label="config.label">
                    </DynamicInput>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicInput from '../../components/DynamicInput.vue';
import ReferralRulesInput from '../../components/Inputs/ReferralRulesInput.vue';

export default {
  data() {
    return {
      filterGroups: [
        'clinical_referral_rules',
      ],
    };
  },
  components: {
    DynamicInput,
    ReferralRulesInput,
  },
  methods: {
    onChange(config) {
      this.$emit('update-configs', config);
    },
  },
  created() {
    this.configs = (this.$attrs.value || []).filter(f => this.filterGroups.indexOf(f.key) !== -1);
  },
};
</script>

<style lang="scss" scoped>
  .row {
    margin: 10px 0px 10px 0px;
  }
</style>
