<template>
  <div class="w-100">
    <div class="table-responsive">
      <table class="table border">
        <thead>
          <tr>
            <th v-for="(header, index) in getHeaders()" :key="index">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in value" :key="item.key">
            <td v-for="config in item.value" :key="config.key">
              <!-- boolean -->
              <span v-if="config.type === 'boolean' && isVisible(config)">
                <div class="form-check mb-2">
                  <toggle-button :disabled="isReadOnly(config)" :value="config.value" @input="updateValue({ item: config, value: $event })" />
                </div>
              </span>

              <!-- number -->
              <span v-else-if="config.type === 'integer' && isVisible(config)">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="number"
                    :value="config.value"
                    @input="updateValue({ item: config, value: $event.target.value })"
                    required
                    :readonly="isReadOnly(config)"
                    :disabled="isReadOnly(config)"
                    v-bind="config.options"/>
                </div>
              </span>

              <!-- select -->
              <span v-else>
                <div class="form-group">
                  <span v-if="config.type == 'select' && isVisible(config)">
                    <select
                      class="form-control"
                      :value="config.value"
                      @change="updateValue({ item: config, value: $event.target.value })"
                      required>
                      <option v-for="option in getOptions(config)" :key="option" :value="option">
                        {{ option }}
                      </option>
                    </select>
                  </span>
                  <span v-else-if="isVisible(config)"> <!-- text -->
                    <input
                      class="form-control"
                      type="text"
                      :value="config.value"
                      @input="updateValue({ item: config, value: $event.target.value })"
                      required
                      :readonly="isReadOnly(config)"/>
                  </span>
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputText',
  props: [ 'inputKey', 'value', 'type', 'label', 'label-right' ],
  model: {
    prop: 'value',
    event: 'input',
    checked: Boolean,
  },
  methods: {
    getHeaders() {
      const keys = [];
      if (Array.isArray(this.value) && this.value.length > 0) {
        if (this.value[0] && this.value[0].value) {
          this.value[0].value.forEach(el => {
            // if no visible option is provide, the default value is true
            if (this._isBoolean(el, 'visible')) {
              if (!el.options.visible) {
                return;
              }
            }
            keys.push(el.label);
          });
        }
      }
      return keys;
    },
    _isBoolean(data, property) {
      return typeof (data && data.options && data.options[property]) === 'boolean';
    },
    isVisible(data) {
      return this._isBoolean(data, 'visible') ? data.options.visible : true;
    },
    getOptions(data) {
      return (data.options && data.options.select_options) || [];
    },
    isReadOnly(data) {
      return this._isBoolean(data, 'read_only') ? data.options.read_only : false;
    },
    updateValue({ item, value }) {
      item.value = value;
      this.$emit('change', { key: this.inputKey, value: this.value });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
}
th,
td {
  padding: 15px;
  text-align: left;
}
</style>
