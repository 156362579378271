<template>
  <div class="configs-module">
    <div class="row col-12">
      <h1>Configs</h1>
    </div>
    <div v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div v-else class="row">
      <component
          v-if="configs"
          v-bind:is="configComponent"
          @update-configs="onUpdateConfigs"
          v-model="configs"
          :ref="this"></component>
    </div>
    <FloatingButtons :buttons="buttons" v-on:floating-button-click="onFloatingButtonClick"></FloatingButtons>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FloatingButtons from '@/components/FloatingButtons.vue';
import ReferralRulesConfig from './Configurations/ReferralRulesConfig.vue';

export default {
  data: () => ({
    loading: false,
    configs: null,
    changedConfigs: [],
  }),
  components: {
    FloatingButtons,
    ReferralRulesConfig,
  },
  computed: {
    configComponent: vm => {
      const group = (vm.$route.params.group || '').toLowerCase().trim();

      switch (group) {
        case 'referral-rules':
          return ReferralRulesConfig;
        default:
          throw new Error('group not configured');
      }
    },
    buttons() {
      let saveText = 'Save';
      saveText += ` (${(this.changedConfigs || []).length})`;
      return {
        save: {
          icon: 'save',
          text: saveText,
          color: 'primary',
        },
      };
    },
  },
  methods: {
    ...mapState('Config2', [ 'getAll', 'save' ]),
    fillConfigs() {
      this.configs = null;
      this.changedConfigs = [];
      this.loading = true;
      this.$store
        .dispatch('Config2/getAll')
        .then(data => {
          data.forEach(el => {
            if (el.type === 'json') {
              try {
                el.value = JSON.parse(el.value);
              } catch {
                el.value = el.value;
              }
            }
            this.configs = data;
          });
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.configs = null;
          this.changedConfigs = [];
        });
    },
    onFloatingButtonClick(event) {
      if (event === 'cancel') {
        this.fillConfigs();
        return;
      }
      this.$store.dispatch('Config2/save', this.changedConfigs).then(() => {
        this.fillConfigs();
      });
    },
    onUpdateConfigs(config) {
      const existing = this.changedConfigs.find(f => f.key === config.key);

      if (!existing) {
        const conf = this.configs.find(f => f.key === config.key);

        if (!conf) return;

        conf.value = config.value;
        this.changedConfigs.push(conf);
        return;
      }

      existing.value = config.value;
    },
  },
  created() {
    this.fillConfigs();
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px 0px 10px 0px;
}
</style>
